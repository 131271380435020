// Cores
    // Azul | Azul oficial - 100
    $k-azul-100: mix(white, #009ADA, 51%);
    $k-azul-300: mix(white, #009ADA, 30%);
    $k-azul-500: #009ADA;
    $k-azul-700: mix(black, #009ADA, 25%);
    $k-azul-900: mix(black, #009ADA, 45%);

    // Cinza | Cinza oficial - 500
    $k-cinza-100: mix(white, #818A8F, 60%);
    $k-cinza-300: mix(white, #818A8F, 35%);
    $k-cinza-500: #818A8F;
    $k-cinza-700: mix(black, #818A8F, 30%);
    $k-cinza-900: mix(black, #818A8F, 50%);

    // Rosa | Rosa oficial - 500
    $k-rosa-100: mix(white, #DA39AF, 60%);
    $k-rosa-300: mix(white, #DA39AF, 35%);
    $k-rosa-500: #DA39AF;
    $k-rosa-700: mix(black, #DA39AF, 30%);
    $k-rosa-900: mix(black, #DA39AF, 50%);

    // Laranja | Laranja oficial - 500
    $k-laranja-100: mix(white, #FF8000, 50%);
    $k-laranja-300: mix(white, #FF8000, 30%);
    $k-laranja-500: #FF8000;
    $k-laranja-700: mix(black, #FF8000, 30%);
    $k-laranja-900: mix(black, #FF8000, 50%);

    // Outras
        // Vermelho | Erros
        $k-vermelho-100: mix(white, #ff3333, 70%);
        $k-vermelho-300: mix(white, #ff3333, 40%);
        $k-vermelho-500: #ff3333;
        $k-vermelho-700: mix(black, #ff3333, 40%);
        $k-vermelho-900: mix(black, #ff3333, 70%);

        // Verde | Sucessos
        $k-verde-100: mix(white, #33cc00, 70%);
        $k-verde-300: mix(white, #33cc00, 45%);
        $k-verde-500: #33cc00;
        $k-verde-700: mix(black, #33cc00, 40%);
        $k-verde-900: mix(black, #33cc00, 70%);
    //
//

/*
    FONTES
    Família: Montserrat
    Tamanhos: 
        Ligth 300 italic
        Light 300
        Regular 400
        Light 400 italic
        Medium 500
        Medium 500 italic
        Semi-bold 600
        Semi-bold 600 italic
        Bold 700
*/

/* 
    Tamanho em REM - Margens
    5px - 
    10px - 
    15px - 
    20px -
*/

